import React from "react";
import coupleBG from "../assets/images/11.jpg";
import { getLocalStorage, setLocalStorage, removeLocalStorage } from "../Utils/LocalStorage";
// import AmeotechLogo from '../assets/images/ameotech-logo-11.png'
const Footer = () => {
  const handleLogout = (e) => {
    e.preventDefault()
    removeLocalStorage('access_token')
    removeLocalStorage('refresh_token')
    removeLocalStorage('user_id')
    removeLocalStorage('partner_role')
    removeLocalStorage('location_Price')
    window.location.href = "/join-as-partner"
  }
  return (
    <div id="sticky-footer"className="footer-container">
       <footer className="customFooter">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h5>Company</h5>
              <ul>
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Matrimony Kodava</a></li> */}
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Careers</a></li> */}
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Community Guidelines</a></li> */}
                <li><a href="/contact-us">Contact Us</a></li>
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">FAQ</a></li> */}
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Newsroom</a></li> */}
                <li><a href="/privacy-policy" >Privacy Policy</a></li>
                <li><a href="/term-conditions" >Terms & Conditions</a></li>
                <li><a href="/refund-policy">Refund policy</a></li>
                <li><a href="/disclaimer">Disclaimer</a></li>
                <li><a onClick={(e) => handleLogout(e)}>Register as Partner</a></li>
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Brand Partnerships</a></li> */}
                {/* <li><a href="#" data-toggle="modal" data-target="#myModal">Register as an Astrologer</a></li> */}
              </ul>
            </div>

            {/* <div className="col-md-3">
              <h5>Explore</h5>
              <ul>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Astrology Services</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Human Matchmaker</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Kundali Matching</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Love Coach</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Marriage Biodata Format</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Personal Loan for Marriage</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Nakshatra</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Numerology</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Success Stories</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Wedding Invitation Cards</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Wedding Photography</a></li>
                <li><a href="#" data-toggle="modal" data-target="#myModal">Wedding Services</a></li>
              </ul>
            </div> */}

            <div className="col-md-3">
              <h5>Contact Us</h5>
              <ul>
                <li><a href="mailto:mrwedsmrs@theworklabs.com">mrwedsmrs@theworklabs.com</a></li>
                <li><a href="#" >For Matchmaking +91 636-631-1577</a></li>
                <li><a href="#" >For Wedding Services +91 636-631-1577</a></li>
              </ul>
            </div>

            <div className="col-md-3">
              <h5>Follow Us On</h5>
              <ul className="socialLinks">
                <li><a href="https://www.instagram.com/mrwedsmrsmatrimony/"><i className="fab fa-instagram"></i> Instagram</a></li>
                <li><a href="https://www.facebook.com/profile.php?id=61563062053994"><i className="fab fa-facebook-f"></i> Facebook</a></li>
                {/* <li><a href="www.youtube.com"><i className="fab fa-youtube"></i> Youtube</a></li>
                <li><a href="www.linkedin.com"><i className="fab fa-linkedin-in"></i>Linkedin</a></li>
                <li><a href="www.twitter.com"><i className="fab fa-twitter"></i>Twitter</a></li> */}
              </ul>
            </div>

            <div className="col-12">
              <div className="copyright text-center mt-4 p-3">© Copyright 2024 All Rights Reserved.</div>
              </div>
          </div>
        </div>
      </footer>
      <div class="modal" id="myModal">
        <div class="modal-dialog customModal">
          <div class="modal-content">

            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal"><i class="fa fa-times-circle" aria-hidden="true"></i></button>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-md-6 photoprimary" style={{ backgroundImage: `url(${coupleBG})` }}>

                </div>
                <div className="col-md-6 p-5 text-center secondary">
                  <h2 className="text-white">We are Coming Soon</h2>
                  <p className="text-white">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                  <ul className="modalSocial d-flex mt-5 justify-content-center align-items-center">
                    <li><a href="www.instagram.com" className="text-white"><i className="fab fa-instagram"></i></a></li>
                    <li><a href="www.facebook.com" className="text-white"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="www.youtube.com" className="text-white"><i className="fab fa-youtube"></i></a></li>
                    <li><a href="www.linkedin.com" className="text-white"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="www.twitter.com" className="text-white"><i className="fab fa-twitter"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
