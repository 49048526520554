export const REGISTER_REQUEST = "REGISTER_REQUEST"
export const REGISTER_SUCCESS = "REGISTER_SUCCESS"
export const REGISTER_FAILURE = "REGISTER_FAILURE"


export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE"

export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"


export const RELIGION_REQUEST = "RELIGION_REQUEST"
export const RELIGION_SUCCESS = "RELIGION_SUCCESS"
export const RELIGION_FAILURE = "RELIGION_FAILURE"


export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST"
export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS"
export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE"

export const COMMUNITIES_REQUEST = "COMMUNITIES_REQUEST"
export const COMMUNITIES_SUCCESS = "COMMUNITIES_SUCCESS"
export const COMMUNITIES_FAILURE = "COMMUNITIES_FAILURE"

export const FAMILYNAME_REQUEST = "FAMILYNAME_REQUEST"
export const FAMILYNAME_SUCCESS = "FAMILYNAME_SUCCESS"
export const FAMILYNAME_FAILURE = "FAMILYNAME_FAILURE"

export const PROFILE_REQUEST = "PROFILE_REQUEST"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_FAILURE = "PROFILE_FAILURE"

export const PROFILE_POST_REQUEST = "PROFILE_POST_REQUEST"
export const PROFILE_POST_SUCCESS = "PROFILE_POST_SUCCESS"
export const PROFILE_POST_FAILURE = "PROFILE_POST_FAILURE"

export const PROFILE_USER_REQUEST = "PROFILE_USER_REQUEST"
export const PROFILE_USER_SUCCESS = "PROFILE_USER_SUCCESS"
export const PROFILE_USER_FAILURE = "PROFILE_USER_FAILURE"

export const PRICE_AND_LOCATION_REQUEST = "PRICE_AND_LOCATION_REQUEST"
export const PRICE_AND_LOCATION_SUCCESS = "PRICE_AND_LOCATION_SUCCESS"
export const PRICE_AND_LOCATION_FAILURE = "PRICE_AND_LOCATION_FAILURE"

export const PROFILE_IMAGE_USER_REQUEST = "PROFILE_IMAGE_USER_REQUEST"
export const PROFILE_IMAGE_USER_SUCCESS = "PROFILE_IMAGE_USER_SUCCESS"
export const PROFILE_IMAGE_USER_FAILURE = "PROFILE_IMAGE_USER_FAILURE"

export const GET_PROFILE_IMAGE_REQUEST = "GET_PROFILE_IMAGE_REQUEST"
export const GET_PROFILE_IMAGE_SUCCESS = "GET_PROFILE_IMAGE_SUCCESS"
export const GET_PROFILE_IMAGE_FAILURE = "GET_PROFILE_IMAGE_FAILURE"

export const LIKED_USER_REQUEST = "LIKED_USER_REQUEST"
export const LIKED_USER_SUCCESS = "LIKED_USER_SUCCESS"
export const LIKED_USER_FAILURE = "LIKED_USER_FAILURE"


export const SUCCESS_STORIES_REQUEST = "SUCCESS_STORIES_REQUEST"
export const SUCCESS_STORIES_SUCCESS = "SUCCESS_STORIES_SUCCESS"
export const SUCCESS_STORIES_FAILURE = "SUCCESS_STORIES_FAILURE"

export const GET_ALL_PROFILE_REQUEST = "GET_ALL_PROFILE_REQUEST"
export const GET_ALL_PROFILE_SUCCESS = "GET_ALL_PROFILE_SUCCESS"
export const GET_ALL_PROFILE_FAILURE = "GET_ALL_PROFILE_FAILURE"

export const GET_SEARCH_PROFILE_REQUEST = "GET_SEARCH_PROFILE_REQUEST"
export const GET_SEARCH_PROFILE_SUCCESS = "GET_SEARCH_PROFILE_SUCCESS"
export const GET_SEARCH_PROFILE_FAILURE = "GET_SEARCH_PROFILE_FAILURE"

export const SEARCH_PROFILE_BY_ID_REQUEST = "SEARCH_PROFILE_BY_ID_REQUEST"
export const SEARCH_PROFILE_BY_ID_SUCCESS = "SEARCH_PROFILE_BY_ID_SUCCESS"
export const SEARCH_PROFILE_BY_ID_FAILURE = "SEARCH_PROFILE_BY_ID_FAILURE"

export const USER_PICTURE_REQUEST = "USER_PICTURE_REQUEST"
export const USER_PICTURE_SUCCESS = "USER_PICTURE_SUCCESS"
export const USER_PICTURE_FAILURE = "USER_PICTURE_FAILURE"

export const FRIEND_REQUEST_REQUEST = "FRIEND_REQUEST_REQUEST"
export const FRIEND_REQUEST_SUCCESS = "FRIEND_REQUEST_SUCCESS"
export const FRIEND_REQUEST_FAILURE = "FRIEND_REQUEST_FAILURE"

export const NOTIFICATION_REQUEST = "NOTIFICATION_REQUEST"
export const NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS"
export const NOTIFICATION_FAILURE = "NOTIFICATION_FAILURE"


export const ACCEPT_REQUEST = "ACCEPT_REQUEST"
export const ACCEPT_SUCCESS = "ACCEPT_SUCCESS"
export const ACCEPT_FAILURE = "ACCEPT_FAILURE"

export const FRIEND_LIST_REQUEST = "FRIEND_LIST_REQUEST"
export const FRIEND_LIST_SUCCESS = "FRIEND_LIST_SUCCESS"
export const FRIEND_LIST_FAILURE = "FRIEND_LIST_FAILURE"

export const CREATE_ROOM_REQUEST = "CREATE_ROOM_REQUEST"
export const CREATE_ROOM_SUCCESS = "CREATE_ROOM_SUCCESS"
export const CREATE_ROOM_FAILURE = "CREATE_ROOM_FAILURE"

export const PROFILE_IMAGE_UPDATE_REQUEST = "PROFILE_IMAGE_UPDATE_REQUEST"
export const PROFILE_IMAGE_UPDATE_SUCCESS = "PROFILE_IMAGE_UPDATE_SUCCESS"
export const PROFILE_IMAGE_UPDATE_FAILURE = "PROFILE_IMAGE_UPDATE_FAILURE"

export const DOCUMENT_VERIFY_REQUEST = "DOCUMENT_VERIFY_REQUEST"
export const DOCUMENT_VERIFY_SUCCESS = "DOCUMENT_VERIFY_SUCCESS"
export const DOCUMENT_VERIFY_FAILURE = "DOCUMENT_VERIFY_FAILURE"


export const PROFILE_DATA_UPDATE_REQUEST = "PROFILE_DATA_UPDATE_REQUEST"
export const PROFILE_DATA_UPDATE_SUCCESS = "PROFILE_DATA_UPDATE_SUCCESS"
export const PROFILE_DATA_UPDATE_FAILURE = "PROFILE_DATA_UPDATE_FAILURE"

export const ADVANCE_SEARCH_PROFILE_REQUEST = "ADVANCE_SEARCH_PROFILE_REQUEST"
export const ADVANCE_SEARCH_PROFILE_SUCCESS = "ADVANCE_SEARCH_PROFILE_SUCCESS"
export const ADVANCE_SEARCH_PROFILE_FAILURE = "ADVANCE_SEARCH_PROFILE_FAILURE"

export const STRIPE_PAYMENT_REQUEST = "STRIPE_PAYMENT_REQUEST"
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS"
export const STRIPE_PAYMENT_FAILURE = "STRIPE_PAYMENT_FAILURE"

export const SUBSCRIPTION_REQUEST = "SUBSCRIPTION_REQUEST"
export const SUBSCRIPTION_SUCCESS = "SUBSCRIPTION_SUCCESS"
export const SUBSCRIPTION_FAILURE = "SUBSCRIPTION_FAILURE"

export const CONTACT_REQUEST = "CONTACT_REQUEST"
export const CONTACT_SUCCESS = "CONTACT_SUCCESS"
export const CONTACT_FAILURE = "CONTACT_FAILURE"

export const USER_IMAGES_REQUEST = "USER_IMAGES_REQUEST"
export const USER_IMAGES_SUCCESS = "USER_IMAGEST_SUCCESS"
export const USER_IMAGES_FAILURE = "USER_IMAGES_FAILURE"

export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST"
export const FEEDBACK_SUCCESS = "FEEDBACK_SUCCESS"
export const FEEDBACK_FAILURE = "FEEDBACK_FAILURE"

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST"
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS"
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE"

export const USER_PREFERENCES_REQUEST = "USER_PREFERENCES_REQUEST"
export const USER_PREFERENCES_SUCCESS = "USER_PREFERENCES_SUCCESS"
export const USER_PREFERENCES_FAILURE = "USER_PREFERENCES_FAILURE"

export const ADD_PREFERENCES_REQUEST = "ADD_USER_PREFERENCES_REQUEST"
export const ADD_PREFERENCES_SUCCESS = "ADD_USER_PREFERENCES_SUCCESS"
export const ADD_PREFERENCES_FAILURE = "ADD_USER_PREFERENCES_FAILURE"

export const AI_MESSAGE_REQUEST = "AI_MESSAGE_REQUEST"
export const AI_MESSAGE_SUCCESS = "AI_MESSAGE_SUCCESS"
export const AI_MESSAGE_FAILURE = "AI_MESSAGE_FAILURE"
