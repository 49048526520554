import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addPriceAndLocation } from "../../Redux/Actions/ProfileActions";
import { IoArrowBack } from "react-icons/io5";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "../../Utils/LocalStorage";
import axios from "axios";
import { Api } from "../../Utils/ApiUrl";
import PageLoader from "../pageLoader";
import { toastify } from "../../Utils/Function";
import { toast } from "react-toastify";

const PriceAndLocationModal = ({ setShowModal, modalShow }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    price: "",
    location: "",
  });
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = getLocalStorage("user_id")
    console.log("error........", formData);
    if (formData.price <= 0 || formData.location?.length === 0) {
      setError(true);
      return;
    } else {
      setLoader(true)
      axios.put(Api.partnerService(id),{price: +formData.price, location: formData.location})
            .then((response) => {
                setLocalStorage("location_Price",true)
                // dispatch(priceAndLocationSuccess(response.data))
                toastify(toast.success, "Added Successfully", "dark")
                window.location.href = "/partner-dashboard"
            })
            .catch((error) => {
              setLoader(false);
                // dispatch(priceAndLocationFailure(error))
                if(error?.response?.data?.user){
                  toast.dismiss()
                    toastify(toast.error, error?.response?.data?.user[0], "dark");
                    } else {
                      toastify(toast.error, "Something went wrong", "dark");
                    }
            })
      // dispatch(addPriceAndLocation({price: +formData.price, location: formData.location}));
    }
  };

  const handleLogout = (e) => {
    e.preventDefault()
    removeLocalStorage('access_token')
    removeLocalStorage('refresh_token')
    removeLocalStorage('user_id')
    removeLocalStorage('partner_role')
    removeLocalStorage('location_Price')
    window.location.href = "/login"
  }

  const handleOnChangePrice = (val)=> {
    const value = val.replace(/[^0-9.]|(\..*)\./g, '$1');
    setFormData({ ...formData, price: value })
  }

  const handleOnBlur = () => {
    const temPrice = parseFloat(formData.price).toFixed(2)
    setFormData({ ...formData, price: temPrice})
  }
  return (
    <Modal
      show={modalShow}
      // onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
          <div className="backBtn">
            <h5 onClick={handleLogout} style={{cursor: "pointer"}} > <IoArrowBack /> Back To Login Page</h5>
          </div>
      <Modal.Header />
      <Modal.Body>
        <div className="modal-body">
          <div className="text text-center">
            <h6>Enter Your the Price and location</h6>
          </div>
          {loader && <PageLoader />}
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="profile-edit-two">
              <li>
                <label>Location</label>
                <input
                  type="text"
                  name="location"
                  placeholder="Location"
                  // value={editProfileData?.name}
                  onChange={(e) =>
                    setFormData({ ...formData, location: e.target.value })
                  }
                ></input>
                {!formData.location?.length && error && (
                  <p className="form-text" style={{ color: "red" }}>
                    Location is Required
                  </p>
                )}
              </li>
            </div>
            <div className="profile-edit-two">
              <li>
                <label>Price</label>
                <input
                  type="text"
                  name="price"
                  placeholder="Price"
                  value={formData?.price}
                  onChange={(e) => handleOnChangePrice(e.target.value)}
                  onBlur={handleOnBlur}
                ></input>
                {`${formData.price}`.length <= 0 && error && (
                  <p className="form-text" style={{ color: "red" }}>
                    Price is Required
                  </p>
                )}
                { `${formData.price}`.length > 0 && formData.price <= 0 && error && (
                  <p className="form-text" style={{ color: "red" }}>
                  Enter a valid price
                </p>
                )}
              </li>
            </div>
            <div className="btn-box text-center my-4">
              <button className="btn btn-primary mx-2" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PriceAndLocationModal;
