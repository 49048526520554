import React from "react";
import "./Stories.css";


const PageLoader = () => {
  return (
    <div className="CustomLoader">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default PageLoader;
