import React from "react";
import { lazy, Suspense } from "react";
import Loading from "../components/Loading";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Payment from "../Pages/Payment";

// import TestLike from '../components/LikeDislikeCard/TestLike';

const Profile = lazy(() => import("../Pages/Profile"));
const PartnerProfile = lazy(() => import("../Pages/PartnerProfile"));
const Feed = lazy(() => import("../Container/HomeContainer"));
const SignUp = lazy(() => import("../Pages/AuthPages/SignUp"));
const JoinAsPartner = lazy(() => import("../Pages/AuthPages/JoinAsPartner"));
const PartnerDashboard = lazy(() => import("../Pages/PartnerDashboard"));
const QuarryServices = lazy(() => import("../Pages/QuarryServices"));
const Login = lazy(() => import("../Pages/AuthPages/Login"));
const ForgotPassword = lazy(() => import("../Pages/AuthPages/ForgotPassword"));
const MemberShip = lazy(() => import("../Pages/MemberShipPlans/MemberShip"));
const ConfirmPassword = lazy(() =>
  import("../Pages/AuthPages/ConfirmPassword")
);
const AboutUs = lazy(() => import("../Pages/About/AboutUs"));
const PrivacyPolicy = lazy(() =>
  import("../Pages/PrivacyPolicy/PrivacyPolicy")
);
const Contactus = lazy(() => import("../Pages/ContactUs"));
const ProfileUserDetail = lazy(() =>
  import("../Pages/Profile/ProfileUserDetail")
);
const UserProfile = lazy(() => import("../Pages/Profile/UserProfile"));
const LikeDislikeCard = lazy(() => import("../components/LikeDislikeCard"));
const Chat = lazy(() => import("../Pages/chat"));
const Notification = lazy(() => import("../Pages/Notification"));
const Test = lazy(() => import("../Pages/chat/Test"));
const Service = lazy(() => import("../Pages/Services/Service"));
const TermConditions = lazy(() => import("../Pages/TermCondtions/TermCondtion"));
const RefundPolicy = lazy(() => import("../Pages/Refund policy/refundPolicy"));
const Disclaimer = lazy(() => import("../Pages/Disclaimer/Disclaimer"));
const AppRoutes = ({ auth, isPartner }) => {
  return (
    <Routes>
      {/* private routes start here*/}
      {isPartner ? (
        <>
          <Route
            path="/profile"
            Component={(props) =>
              auth && isPartner ? (
                <Suspense fallback={<Loading />}>
                  <PartnerProfile {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/partner-dashboard"
            Component={(props) =>
              auth && isPartner ? (
                <Suspense fallback={<Loading />}>
                  <PartnerDashboard {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/booking-details"
            Component={(props) =>
              auth && isPartner ? (
                <Suspense fallback={<Loading />}>
                  <QuarryServices {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/"
            Component={(props) =>
              auth ? (
                <Suspense fallback={<Loading />}>
                  <PartnerDashboard {...props} />{" "}
                </Suspense>
              ) : (
                null
                // <Navigate to="/login" replace />
              )
            }
          />
        </>
      ) : (
        <>
          <Route
            path="/profile"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <Profile {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/profile-info"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <ProfileUserDetail {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/searchprofiles"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <LikeDislikeCard {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          <Route
            path="/service"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <Service {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />

          <Route
            path="/chat"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <Chat {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
          <Route
            path="/all-notification"
            Component={(props) =>
              auth && !isPartner ? (
                <Suspense fallback={<Loading />}>
                  <Notification {...props} />{" "}
                </Suspense>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </>
      )}
      {/* private routes end here*/}

      {/* unauthorized routes start here */}
      <Route
        path="/login"
        Component={(props) =>
          !auth ? (
            <Suspense fallback={<Loading />}>
              <Login {...props} />
            </Suspense>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route
        path="/forgot-password"
        Component={(props) =>
          !auth ? (
            <Suspense fallback={<Loading />}>
              <ForgotPassword {...props} />
            </Suspense>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />

      <Route
        path="/reset-password"
        Component={(props) =>
          !auth ? (
            <Suspense fallback={<Loading />}>
              <ConfirmPassword {...props} />
            </Suspense>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route
        path="/signup"
        Component={(props) =>
          !auth ? (
            <Suspense fallback={<Loading />}>
              <SignUp {...props} />
            </Suspense>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      <Route
        path="/join-as-partner"
        Component={(props) =>
          !auth ? (
            <Suspense fallback={<Loading />}>
              <JoinAsPartner {...props} />
            </Suspense>
          ) : (
            <Navigate to="/" replace />
          )
        }
      />
      {/* unauthorized routes start here */}

      {/* public  routes start here*/}
      <Route
        path="/contact-us"
        element={
          <Suspense fallback={<Loading />}>
            <Contactus />
          </Suspense>
        }
      />
      {/* <Route path="/connection" element={<Suspense fallback={<Loading />}><TestLike /></Suspense>} /> */}
      <Route
        path="/connection"
        element={
          <Suspense fallback={<Loading />}>
            <LikeDislikeCard />
          </Suspense>
        }
      />
      <Route
        path="/"
        element={
          <Suspense fallback={<Loading />}>
            <Feed />
          </Suspense>
        }
      />
      <Route
        path="/membership"
        element={
          auth ? (
            <Suspense fallback={<Loading />}>
              <MemberShip />
            </Suspense>
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route
        path="/payment/:id"
        element={
          <Suspense fallback={<Loading />}>
            <Payment />
          </Suspense>
        }
      />
      {/* <Route path="/payment/:id" Component={(props) => { <Suspense fallback={<Loading />}><Payment {...props} /></Suspense> }} /> */}
      <Route
        path="/about"
        element={
          <Suspense fallback={<Loading />}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <Suspense fallback={<Loading />}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path="/term-conditions"
        element={
          <Suspense fallback={<Loading />}>
            <TermConditions />
          </Suspense>
        }
      />
      <Route
        path="/user-profile"
        element={
          <Suspense fallback={<Loading />}>
            <UserProfile />
          </Suspense>
        }
      />
      
      <Route
        path="/refund-policy"
        element={
          <Suspense fallback={<Loading />}>
            <RefundPolicy />
          </Suspense>
        }
      />
      <Route
        path="/disclaimer"
        element={
          <Suspense fallback={<Loading />}>
            <Disclaimer />
          </Suspense>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
      {/* public  routes end here*/}
    </Routes>
  );
};

export default AppRoutes;
